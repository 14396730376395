a.current-menu-item {
  text-decoration: none;
  color: #c2572b !important;
}

.mobile-only {
  display: none;
}

.logo-middle {
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  font-family: "Gerlomi";
  font-weight: bold;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 991px) {
  .mobile-only {
    display: block;
  }
}
